import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, ProgressBar, Alert } from "react-bootstrap";
import AuthContext from "../context/AuthContext";

export default class CampaignList extends React.Component {
  render() {
    console.log("CONTEXT IN CampaignList.js: ", this.context);
    // if plan is Free plan set the limit to 20,000 characters
    // if plan is Pro plan set the limit to 100,000 characters
    let translations_limit = this.context.plan === "Free plan" ? 20000 : 100000;
    return (
      <>
        <div style={{ maxWidth: "700px" }}>
          {/* if no translation made, show how-to */}
          {this.context.translations === 0 ? (
            <>
              <Card className="text-bg-light">
                <Card.Body>
                  <Card.Title>Next steps</Card.Title>
                  <Card.Text>
                    <ol>
                      <li>
                        <strong>
                          Install the{" "}
                          <a
                            href="https://chrome.google.com/webstore/detail/duolingo-ninja/cdiecbgkdbkloiniekbfblbdbjgehpgb"
                            target="_blank"
                          >
                            Duolingo Ninja browser extension
                          </a>
                        </strong>{" "}
                        (for desktop browsers only)
                      </li>
                      <li>
                        <strong>
                          Synchronize the words you've learned with Duolingo
                          with the extension
                        </strong>{" "}
                        (note that Duolingo does not provide vocabulary for
                        every course)
                      </li>
                      <li>
                        Use the browser extension to:
                        <ul>
                          <li>See and download your Duolingo vocabulary</li>
                          <li>
                            Translate words or sentences on web pages
                            automatically to learn them while browsing the web
                          </li>
                          <li>Practice the words with flashcards</li>
                        </ul>
                      </li>

                      {/* <li>
                        See the translation statistics either on this page or in the
                        extension
                      </li> */}
                    </ol>
                  </Card.Text>
                </Card.Body>
              </Card>
              <br />
            </>
          ) : (
            <></>
          )}

          <Card>
            <Card.Body>
              <Card.Title>Statistics</Card.Title>
              <>
                <Card.Text>
                  This is your running monthly usage of translations in
                  characters.
                </Card.Text>
                <Card.Text>
                  <h6>
                    Characters used:{" "}
                    {this.context.translations.toLocaleString("en")} /{" "}
                    {translations_limit}
                  </h6>
                </Card.Text>
                <ProgressBar
                  now={(this.context.translations / translations_limit) * 100}
                  label={`${
                    Math.round(
                      (this.context.translations / translations_limit) *
                        100 *
                        10
                    ) / 10
                  }%`}
                  className="my-3"
                />
                <Card.Text>
                  Note that average word length varies a lot by language. In
                  English it's about 5 characters per word. So if you translate
                  100 words, you'll use approximately 500 characters.
                </Card.Text>
              </>
            </Card.Body>
          </Card>
          {this.context.translations > 0 && (
            <Alert variant="warning" className="mt-4">
              <Alert.Heading>Where are the words?</Alert.Heading>
              <p>
                The Duolingo Ninja website (including the dashboard) does not
                store your words. They are stored in the Duolingo Ninja browser
                extension. To see the vocabulary, open up the browser extension
                and follow the instruction there.
                <br />
                <br />
                See the instructions on how to access browser extensions for{" "}
                <a
                  href="https://maschituts.com/how-to-open-extensions-in-chrome/"
                  target="_blank"
                >
                  Chrome
                </a>
                ,{" "}
                <a
                  href="https://support.microsoft.com/en-us/microsoft-edge/add-turn-off-or-remove-extensions-in-microsoft-edge-9c0ec68c-2fbc-2f2c-9ff0-bdc76f46b026"
                  target="_blank"
                >
                  Edge
                </a>
                ,{" "}
                <a
                  href="https://kb.mit.edu/confluence/display/istcontrib/Enable-disable+plugins+and+extensions+for+Mozilla+Firefox"
                  target="_blank"
                >
                  Firefox
                </a>
                ,{" "}
                <a
                  href="https://help.opera.com/en/latest/customization/"
                  target="_blank"
                >
                  Opera
                </a>
                , and{" "}
                <a
                  href="https://support.brave.com/hc/en-us/articles/360017909112-How-can-I-add-extensions-to-Brave"
                  target="_blank"
                >
                  Brave
                </a>
                .
              </p>
            </Alert>
          )}
        </div>
      </>
    );
  }
}

CampaignList.contextType = AuthContext;
